<template>
  <section>
    <confirmation ref="Confirmation"></confirmation>
    <div class="additional-site-grid-header mb-3">
      <h1 class="bold-header-text app-header">Applications</h1>
      <button class="base-button" @click="createApplication">Add New</button>
    </div>
    <b-card class="dashboard-card" style="margin-top: 2rem">
      <kendo-grid
        :sortable="true"
        :sort="sort"
        :data-items="gridData"
        :columns="tableColumns"
        @datastatechange="dataStateChange"
      >
        <template v-slot:myTemplate="{ props }">
          <td class="k-command-cell">
            <custom
              :data-item="props.dataItem"
              @edit="editRecord"
              @remove="deleteRecord"
              :showUpload="isCorporateCompany"
              uploadFileTypes=".xls,.xlsx"
              uploadTooltip="Upload Completed Application Template"
              @upload="handleFileUpload"
              :showDownload="isCorporateCompany"
              downloadTooltip="Download Application Template"
              downloadPath="../../assets/application-template/test.xlsx"
            />
          </td>
        </template>
        <template v-slot:dateSubmittedTemplate="{ props }">
          <td class="text-center">
            <div>
              <p>{{ formatDate(props.dataItem.dateSubmitted) }}</p>
            </div>
          </td>
        </template>
      </kendo-grid>
    </b-card>
  </section>
</template>
<script>
import { process } from "@progress/kendo-data-query";
import { Grid } from "@progress/kendo-vue-grid";
import ApplicationMixin from "../../mixins/ApplicationMixin.vue";
import { mapGetters } from "vuex";
import ButtonActions from "../../components/Common/ButtonActions.vue";
import Confirmation from "../../components/Common/Confirmation.vue";
import moment from "moment";
import UploadDocumentMixin from "../../mixins/UploadDocumentMixin.vue";
import { bus } from "../../main";

export default {
  props: [],
  components: {
    "kendo-grid": Grid,
    custom: ButtonActions,
    Confirmation,
  },
  mixins: [ApplicationMixin, UploadDocumentMixin],
  data() {
    return {
      tableColumns: [
        {
          cell: "myTemplate",
          title: "Actions",
          width: "100",
        },
        {
          field: "companyID",
          title: "Co.ID",
          width: "80px",
        },
        {
          field: "applicationID",
          title: "App.ID",
          width: "80px",
        },
        {
          field: "status",
          title: "Status",
          width: "120",
        },
        {
          field: "type",
          title: "Type",
          width: "80",
        },
        {
          field: "companyName",
          title: "Company Name",
          width: "200",
        },
        {
          field: "address",
          title: "City/State",
          width: "200",
        },
        {
          field: "programs",
          title: "Programs",
          width: "200",
        },
      ],
      gridData: [],
      sort: [],
      company: {},
      isCorporateCompany: false,
    };
  },
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    this.company = JSON.parse(localStorage.getItem("currentCompany"));
    this.isCorporateCompany = this.company.numberOfLocations > 10;
    this.getTableData();
    setTimeout(() => {
      bus.$emit("showFullpageOverlay", {
        showLayover: false,
      });
    }, 1000);
  },
  methods: {
    async createApplication() {
      const { companyID = "" } = JSON.parse(localStorage.getItem("currentCompany"));
      await this.startApplication(companyID);
      if (this.statusCode && this.statusCode === 200) {
        this.$router.push({
          name: "ApplicationLanding",
          params: { applicationID: this.applicationID },
        });
      }
    },
    async getTableData() {
      await this.getApplications(this.company.companyID);
      this.getData(this.applicationsList);
    },
    getData(data) {
      this.gridData = process(data, {
        sort: this.sort,
      });
    },
    async createAppState(dataState) {
      await this.getApplications(this.company.companyID);
      this.sort = dataState.sort;
      this.getData(this.applicationsList);
    },
    async dataStateChange(event) {
      await this.createAppState(event.data);
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    editRecord(dataItem) {
      this.$router.push({
        name: "ApplicationLanding",
        params: { applicationID: dataItem.applicationID },
      });
    },
    async deleteRecord(dataItem) {
      this.$refs.Confirmation.show({
        title: "Delete Application",
        message: `Are you sure you want to delete Application ${dataItem.applicationID}?`,
        okButton: "Yes",
        cancelButton: "No",
        exitFunction: () => {},
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage("");
          await this.deleteApplication(dataItem.applicationID);
          if (this.statusCode === 200) {
            this.getTableData();
          }
        }
      });
    },
    async handleFileUpload(selectedFiles) {
      // single file upload
      if (selectedFiles.length) {
        let params = { companyID: this.company.companyID };
        await this.sendFiles(params, selectedFiles[0], false);
      }
    },
  },
  computed: {
    ...mapGetters({
      getApplicationsList: "getApplicationsList",
    }),
    applicationsList() {
      return this.getApplicationsList;
    },
  },
};
</script>
